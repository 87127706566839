import { Pipe, PipeTransform } from '@angular/core';
import { localStorageKeys } from '../enums/localStroageKeys.enum';

@Pipe({
  name: 'showAuthProvider',
})
export class AuthproviderPipe implements PipeTransform {
  authProvider: string[] = JSON.parse(
    localStorage.getItem(localStorageKeys.APP_CONFIG),
  )['authProvider'];

  transform(value: string): boolean {
    if (this.authProvider && this.authProvider.indexOf(value) > -1) {
      return true;
    } else {
      return false;
    }
  }
}
