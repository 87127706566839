import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  apiUrl = environment.apiUrl;

  constructor(
    private _httpClient: HttpClient,
    private _authService: AuthenticationService,
  ) {}

  getAllTasks(from?, state?, dateFrom?, dateTo?) {
    const params = {};

    if (from !== undefined) {
      params['from'] = from;
    }
    if (state) {
      params['state'] = state;
    }
    if (dateFrom) {
      params['dateFrom'] = dateFrom;
    }
    if (dateTo) {
      params['dateTo'] = dateTo;
    }
    const options = { params: params, headers: this._authService.authHeader };

    return this._httpClient.post(`${this.apiUrl}Tasks/GetTasks`, {}, options);
  }

  getTasksByTransactionId(transactionId, pageSize?, page?) {
    return this._httpClient.post(
      `${this.apiUrl}Tasks/GetTasks?transactionId=${transactionId}${
        pageSize ? '&' + 'pageSize=' + pageSize : ''
      }${page || page === 0 ? '&' + 'page=' + page : ''}`,
      {},
      { headers: this._authService.authHeader },
    );
  }

  createTask(task) {
    return this._httpClient.post(`${this.apiUrl}Tasks/CreateTask`, task, {
      headers: this._authService.authHeader,
    });
  }

  getTaskById(taskId) {
    return this._httpClient.get(`${this.apiUrl}Tasks/GetTaskById/${taskId}`, {
      headers: this._authService.authHeader,
    });
  }

  extendDueDate(dueDate) {
    return this._httpClient.post(`${this.apiUrl}Tasks/ExtendDueDate`, dueDate, {
      headers: this._authService.authHeader,
    });
  }

  endTask(changeDate) {
    return this._httpClient.post(
      `${this.apiUrl}Tasks/ChangeState`,
      changeDate,
      { headers: this._authService.authHeader },
    );
  }

  alarmRequest(taskId) {
    return this._httpClient.post(`${this.apiUrl}Tasks/AlarmRequest`, taskId, {
      headers: this._authService.authHeader,
    });
  }

  getAttatchmentFile(fileId) {
    return this._httpClient.get(
      `${this.apiUrl}Tasks/GetAttatchmentFile?fileId=${fileId}`,
      { headers: this._authService.authHeader, responseType: 'arraybuffer' },
    );
  }

  sendReplay(replayObj) {
    return this._httpClient.post(`${this.apiUrl}Tasks/Replay`, replayObj, {
      headers: this._authService.authHeader,
    });
  }

  extendRequest(extendRequest) {
    return this._httpClient.post(
      `${this.apiUrl}Tasks/ExtendRequest`,
      extendRequest,
      { headers: this._authService.authHeader },
    );
  }

  resendTask(resendTask) {
    return this._httpClient.post(`${this.apiUrl}Tasks/ResendTask`, resendTask, {
      headers: this._authService.authHeader,
    });
  }

  pinnedTask(taskId, location) {
    return this._httpClient.post(
      `${this.apiUrl}Tasks/Pinned/${taskId}/${location}`,
      {},
      { headers: this._authService.authHeader },
    );
  }

  removepinnedTask(taskId, location) {
    return this._httpClient.post(
      `${this.apiUrl}Tasks/RemovePinned/${taskId}/${location}`,
      {},
      { headers: this._authService.authHeader },
    );
  }

  getAlerts() {
    return this._httpClient.get(`${this.apiUrl}Tasks/GetAlerts`, {
      headers: this._authService.authHeader,
    });
  }

  receiveTask(taskId: any) {
    return this._httpClient.post(
      `${this.apiUrl}Tasks/Take/${taskId}`,
      {},
      {
        headers: this._authService.authHeader,
      },
    );
  }
}
