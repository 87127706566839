import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, Provider } from '@angular/core';
import { RetryInterceptor } from '../retry';

export const RETRY_INTERCEPTOR_CONFIG = new InjectionToken<number>(
    'retryConfig',
    {
      providedIn: 'root',
      factory: () => {
        return 1 as number;
      },
    }
);

export const RetryInterceptorProvider: Provider = {
    provide: HTTP_INTERCEPTORS,
    useClass: RetryInterceptor,
    multi: true,
  };