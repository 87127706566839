export enum transactionActions {
  View_Transaction = 1,
  Print_Transaction = 2,
  Print_Barcode = 3,
  Print_Review = 4,
  Assign_Transaction = 5,
  View_Transaction_Details = 6,
  Edit_Transaction = 7,
  Save_Transaction = 8,
  Search_Transaction = 9,
  Print_Delivery_Statment = 10,
  Add_Signature = 11,
  Add_Stamp = 12,
  Delete_Transaction = 13,
}

/*
// | 1    | استعراض المعاملة       | View Transaction  https://stage.signalx.42.guru/api/Transaction/GetTransactionById        |
| 2    | طباعة المعاملة         | Print Transaction        |
// | 3    | طباعة رمز التشفير      | Print Barcode            |
// | 4    | طباعة تذكرة المراجعة   | Print Review             |
// | 5    | احالة المعاملة         | Assign Transaction       Transaction/Forword |
// | 6    | استعراض شهادة المعاملة | View Transaction Details |
// | 7    | تعديل المعاملة         | Edit Transaction         |
// | 8    | حفظ المعاملة           | Save Transaction         |
// | 9    | بحث عن معاملة          | Search Transaction       |
| 10   | طباعة بيان تسليم       | Print Delivery Statment  |
// | 11   | اضافة توقيع            | Add Signature            |
// | 12   | اضافة ختم              | Add Stamp                |
*/
