import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  apiUrl = environment.apiUrl;

  fileExtObs = new BehaviorSubject('.pdf');

  constructor(
    private _authService: AuthenticationService,
    private _httpClient: HttpClient,
  ) {
    this.getFilesExtentions();
  }

  getFilesExtentions() {
    return of(
      localStorage.getItem('allowdExtenesion')
        ? JSON.parse(localStorage.getItem('allowdExtenesion'))
        : false,
    ).subscribe((exts: any[]) => {
      if (exts) {
        const string = exts.reduce((prev, current) => {
          return prev + `,${current}`;
        });
        //console.log(string);
        if (string.trim() !== '') {
          this.fileExtObs.next(string);
        }
      }
    });
  }

  userUpdate(userUpdate: any) {
    return this._httpClient.post(
      `${this.apiUrl}Account/UpdateUser`,
      userUpdate,
      { headers: this._authService.authHeader },
    );
  }

  editStampAndSignature(signatuteBody, passwordRequired) {
    return this._httpClient.post(
      `${this.apiUrl}Account/EditStampAndSignature?requiredPasswordForStampAndSignature=${passwordRequired}`,
      signatuteBody,
      { headers: this._authService.authHeader },
    );
  }

  getStampAndSignature() {
    return this._httpClient.get(`${this.apiUrl}Account/GetStampAndSignature`, {
      headers: this._authService.authHeader,
    });
  }

  checkPassword(password) {
    return this._httpClient.post(
      `${this.apiUrl}Account/CheckPassword?password=${password}`,
      {},
      { headers: this._authService.authHeader },
    );
  }

  createGroup(group) {
    return this._httpClient.post(`${this.apiUrl}Admin/CreateGroup`, group, {
      headers: this._authService.authHeader,
    });
  }

  updateGroup(updategroup) {
    return this._httpClient.put(
      `${this.apiUrl}Admin/UpdateGroup`,
      updategroup,
      { headers: this._authService.authHeader },
    );
  }

  // getGroups(){
  //   return this._httpClient.get(`${this.apiUrl}Admin/GetGroups`, {headers:this._authService.authHeader})
  // }

  getGroups(limit?, page?) {
    if (limit && page) {
      return this._httpClient.get(
        `${this.apiUrl}Admin/GetGroups${
          limit ? '?' + 'pageSize=' + limit : ''
        }${page || page === 0 ? '&' + 'page=' + page : ''}`,
        { headers: this._authService.authHeader },
      );
    } else {
      return this._httpClient.get(`${this.apiUrl}Admin/GetGroups`, {
        headers: this._authService.authHeader,
      });
      //return this._httpClient.get(`${this.apiUrl}Admin/Lookup/actions${limit ? ('?' + 'pageSize=' + limit ) : ''}${page || page === 0 ? ('&' + 'page=' + page) : ''}`,{headers:this._authService.authHeader})
    }
  }

  getGroupById(id) {
    return this._httpClient.get(`${this.apiUrl}Admin/GetGroup/${id}`, {
      headers: this._authService.authHeader,
    });
  }

  deleteGroup(id) {
    return this._httpClient.delete(`${this.apiUrl}Admin/DeleteGroup?id=${id}`, {
      headers: this._authService.authHeader,
    });
  }
}
