import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { environment } from '../environments/environment';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { initFirebaseBackend } from './authUtils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { ToastrModule } from 'ngx-toastr';
// import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { loader } from './core/interceptor/loader';
import { RetryInterceptorProvider } from './core/interceptor/tokens/retryConfigToken';
import {
  NgbCalendar,
  NgbDatepickerI18n,
  NgbDatepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';

import { appConfigFactory } from './core/helpers/appConfig.factory';

import {
  dateFactory,
  dateFactoryI18n,
} from './core/helpers/datepicker.factory';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      maxOpened: 1,
    }), // ToastrModule added
    LayoutsModule,
    AppRoutingModule,
    ScrollToModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: loader,
      multi: true,
    },
    RetryInterceptorProvider,
    //bootstrap date providers
    {
      provide: NgbCalendar,
      useFactory: dateFactory,
    },
    {
      provide: NgbDatepickerI18n,
      useFactory: dateFactoryI18n,
    },
    // bootstrap date providers,
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [HttpClient],
      multi: true,
    },
    //global config for datepicker
    // {
    //   provide: NgbDatepickerConfig,
    //   useFactory: getDatepickerConfig,
    //   deps: [NgbCalendar],
    // },
  ],
})
export class AppModule {}
