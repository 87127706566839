import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appLoaderImage]',
})
export class ImageLoaderDirective implements OnInit, OnChanges {
  @Input() loadedImage: string | undefined = undefined;
  @Input() defaultImage: string | undefined = undefined;
  dowlnoadedImage = new Image();

  ngOnInit(): void {
    this.defaultImage = this.defaultImage
      ? this.defaultImage
      : '/assets/images/SignalX.svg';
  }

  finalImage: string | undefined = this.defaultImage
    ? this.defaultImage
    : '/assets/images/SignalX.svg';

  @HostBinding('attr.src')
  get function() {
    return this.finalImage;
  }

  ngOnChanges(): void {
    if (this.loadedImage) {
      this.dowlnoadedImage.src = this.loadedImage;
      this.finalImage = '/assets/images/loader.gif';
      this.dowlnoadedImage.onload = () => {
        this.finalImage = this.loadedImage;
      };
      this.dowlnoadedImage.onerror = () => {
        this.finalImage = this.defaultImage;
      };
    }
  }
}
