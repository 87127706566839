export const boxes = [
    {
        nameAr : "الصندوق",
        name : "inbox"
    },
    {
        nameAr : "صندوق الإداره",
        name : "departmentinbox"
    },
    {
        nameAr : "صندوق النسخ",
        name : "copies"
    },
    // {
    //     nameAr : "صندوق الصادر",
    //     name : ""
    // },
    {
        nameAr : "صندوق المرسل",
        name : "sent"
    },
    {
        nameAr : "الأرشيف",
        name : "archive"
    }
]