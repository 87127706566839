import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(
    private _router: Router,
    private _authenticationService: AuthenticationService,
  ) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUserRole = this._authenticationService.userRole.value;
    const currentUser = this._authenticationService.currentUserValue;

    if (currentUser) {
      // check if route is restricted by role
      if (
        route.data.roles &&
        route.data.roles.indexOf(currentUserRole) === -1
      ) {
        // role not authorised so redirect to not-authorized page
        // this._router.navigate(['/pages/miscellaneous/not-authorized']);
        this._authenticationService.DetectUserRoute();
        return false;
      }

      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    // this._router.navigate(['/pages/authentication/login-v2'], { queryParams: { returnUrl: state.url } });
    this._authenticationService.DetectUserRoute();
    return false;
  }
}
