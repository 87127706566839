import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  apiUrl = environment.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthenticationService,
  ) {}

  uploadListFiles(data: FormData) {
    return this.httpClient.post(`${this.apiUrl}`, data, {
      headers: this.authService.authHeader,
    });
  }
}
