import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SystemConfigService } from '../services/system-config.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../services/auth.service';
import { EMPTY, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { isDevMode } from '@angular/core';

export function appConfigFactory(http: HttpClient) {
  // get enviroment api url
  const apiUrl = environment.apiUrl;

  // get styles files name
  localStorage.setItem(
    'ltrFile',
    JSON.stringify(
      document
        .querySelector('link[rel*="stylesheet"]:not([href*="styles-rtl"])')
        .getAttribute('href'),
    ),
  );
  // get styles files name

  // set configration from api
  return () =>
    http.get(`/assets/app-config.json`).pipe(
      catchError((err) => {
        if (err) {
          return of(null);
        }
      }),
      switchMap((config: any) => {
        if (config && !isDevMode()) {
          loadConfig(config);
          return http.get(`${apiUrl}Settings/global`);
        } else {
          return http.get(`${apiUrl}Settings/global`);
        }
      }),
      tap((res: any) => {
        localStorage.setItem('allowHijri', res?.allowHijri);
        localStorage.setItem(
          'allowdExtenesion',
          JSON.stringify(res?.allowdExtenesion),
        );
        document
          .querySelector("link[type='image/x-icon']")
          .setAttribute('href', `${environment.serverUrl}${res.favUrl}`);
        localStorage.setItem('config', JSON.stringify(res));
        environment.clientBrandImage = `${environment.serverUrl}${res.logoUrl}`;
        environment.clientBrandImageSm = `${environment.serverUrl}${res.favUrl}`;
      }),
      catchError((error: any) => {
        return EMPTY;
      }),
    );
}

function loadConfig(config) {
  environment.apiUrl = config.apiUrl;
  environment.serverUrl = config.serverUrl;
  environment.SyncService = config.SyncService;
  environment.documentService = config.documentService;
}
