import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SimplebarAngularModule } from 'simplebar-angular';
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
// import { ClickOutsideModule } from 'ng-click-outside';

import { UIModule } from '../shared/ui/ui.module';
import { LayoutComponent } from './layout.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { VerticalComponent } from './vertical/vertical.component';
import { LanguageService } from '../core/services/language.service';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from '../core/pipes/pipes.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DirectivesModule } from '../core/directives/directives.module';

@NgModule({
  // eslint-disable-next-line max-len
  declarations: [
    LayoutComponent,
    TopbarComponent,
    FooterComponent,
    RightsidebarComponent,
    VerticalComponent,
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    UIModule,
    SimplebarAngularModule,
    NgbTooltipModule,
    NgSelectModule,
    PipesModule,
    DirectivesModule,
  ],
  providers: [LanguageService],
})
export class LayoutsModule {}
