import {
  NgbDatepickerI18nDefault,
  NgbCalendarIslamicUmalqura,
  NgbCalendarGregorian,
  NgbDatepickerConfig,
  NgbCalendar,
} from '@ng-bootstrap/ng-bootstrap';
import { GeorgianI18n, IslamicI18n } from '../services/islamicI18n.service';

export function dateFactoryI18n() {
  const allowHijri = JSON.parse(localStorage.getItem('allowHijri'));
  // return allowHijri ? new IslamicI18n() : new NgbDatepickerI18nDefault('en_US');
  return allowHijri ? new IslamicI18n() : new GeorgianI18n();
}

export function dateFactory() {
  const allowHijri = JSON.parse(localStorage.getItem('allowHijri'));
  return allowHijri
    ? new NgbCalendarIslamicUmalqura()
    : new NgbCalendarGregorian();
}

// export function getDatepickerConfig(
//   calneder: NgbCalendar,
// ): NgbDatepickerConfig {
//   return Object.assign(new NgbDatepickerConfig(), {
//     minDate: calneder.getToday(),
//   });
// }
