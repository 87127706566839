import { formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import autoTable from 'jspdf-autotable';
//import WebViewer from '@pdftron/webviewer';
import {
  PdfViewerComponent,
  ToolbarItem,
} from '@syncfusion/ej2-angular-pdfviewer';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { permissionsStrings } from 'src/app/core/enums/premission';
import { HelperService } from 'src/app/core/helpers/helper.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { ManageDepartmentService } from 'src/app/core/services/manage-department.service';
import { ManageEmployeesService } from 'src/app/core/services/manage-employees.service';
import { TasksService } from 'src/app/core/services/tasks.service';
import { TransactionService } from 'src/app/core/services/transaction.service';
import { ReceiveMethod } from 'src/app/core/staticData/recieveMethod.data';
import { TransactionStateEnumData } from 'src/app/core/staticData/stateType';
import { environment } from 'src/environments/environment';
import { transactionActions } from '../../enums/transactionActions';
import { HijiryDateService } from '../../services/hijiry-date.service';
import { LoaderService } from '../../services/loader.service';
@Component({
  selector: 'app-details-components',
  templateUrl: './details-components.component.html',
  styleUrls: ['./details-components.component.scss'],
})
export class DetailsComponentsComponent implements OnInit, OnDestroy {
  _unsubscribeAll = new Subject();
  transactionId;
  transaction;
  logs: any;
  views: any[] = [];
  allLogs;
  allLogsTotalCount;
  logsTotalCount;
  referralsTotalCount;
  Referrals;
  active = 1;
  transactionAction = transactionActions;
  followupsTransactions: any;
  notes = [];

  //pagination
  currentPage = 1;
  collectionSize = 10;
  pageSize = 10;

  public SyncService = environment.SyncService;
  public document = '';
  @ViewChild('printDetails') printDetails: ElementRef;

  //premissons
  permissionStrings = permissionsStrings;

  @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;
  @ViewChild('attachViewer') attachViewer: PdfViewerComponent;

  @Input() boxType;

  ReceiveMethod = ReceiveMethod;

  TransactionState = TransactionStateEnumData;
  listOfDepartment: any[] = [];
  multipleAttachArray: any[] = [];
  fromDraft = true;
  linkedId;
  constructor(
    private modalService: NgbModal,
    private _employeeService: ManageEmployeesService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _transactionService: TransactionService,
    private _helperService: HelperService,
    private _departmentService: ManageDepartmentService,
    private _authService: AuthenticationService,
    private _tasksService: TasksService,
    private _loaderService: LoaderService,
    private hijriService: HijiryDateService,
  ) {
    this._router.events.subscribe((navEvent) => {
      if (navEvent instanceof NavigationEnd) {
        const location =
          this._router.getCurrentNavigation()?.extras?.state?.location;

        this.linkedId = this._router.getCurrentNavigation()?.extras?.state?.id;

        this.fromDraft = location === this.boxType;
      }
    });
  }

  currentLocation;
  currentUser;

  tasks;

  $DepartmentObs = this._departmentService.getFlatDeprtments();
  $userObs = this._employeeService.getAllUsersDepartment();

  $CollectionObs = combineLatest([this.$DepartmentObs, this.$userObs]).pipe(
    map((res: any) => {
      const Department: any[] = res[0]?.results;
      const users: any[] = res[1]?.results;
      return Department.concat(users);
    }),
  );

  ngOnInit(): void {
    this._activatedRoute.params
      .pipe(
        switchMap((res) => {
          this.transactionId = res['id'];
          return combineLatest([
            this._transactionService
              .getTransaction(this.transactionId)
              .pipe(catchError(() => of(null))),
            this._transactionService
              .getTransactionLogs(this.transactionId)
              .pipe(catchError(() => of(null))),
            this._transactionService
              .getTransactionReferrals(this.transactionId)
              .pipe(catchError(() => of(null))),
            this._departmentService
              .getFlatDeprtments()
              .pipe(catchError(() => of(null))),
            this._transactionService
              .getLatestTransactionLocation(this.transactionId)
              .pipe(catchError(() => of(null))),
            this._tasksService
              .getTasksByTransactionId(this.transactionId)
              .pipe(catchError(() => of(null))),
            this._transactionService
              .getFollowupDetailsTransactions(this.transactionId)
              .pipe(catchError(() => of(null))),
            this._transactionService
              .getAllLogs(this.transactionId)
              .pipe(catchError(() => of(null))),
            //this._transactionService.getLatestTransactionLocation(this.transactionId)
          ]);
        }),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe(
        (res: any) => {
          this.transaction = res[0];
          this.transaction['receiveMethod'] = this.ReceiveMethod.filter(
            (item) => item.id === this.transaction['receiveMethod'],
          )[0];
          this.transaction['state'] = this.TransactionState.filter(
            (item) => item.state === this.transaction['state'],
          )[0];
          this.logs = res[1];
          this.logsTotalCount = res[1]?.totalCount;
          // this.views = res[2]?.results;
          // this.allLogsTotalCount = res[2]?.totalCount;
          this.Referrals = res[2];
          this.referralsTotalCount = res[2]?.totalCount;
          this.listOfDepartment = res[3]?.results;

          this.currentLocation = res[4]?.departmentNameAr;
          this.currentUser = res[4]?.fullNameAr;

          this.tasks = res[5];
          this.followupsTransactions = res[6];
          this.allLogs = res[7];
          this.allLogsTotalCount = res[7]?.totalCount;

          console.log(this.allLogs);

          if (this.transaction['attatchments']?.length > 0) {
            this.transaction['attatchments'].forEach((file) => {
              if (file?.mainFile) {
                this.viewFileFromServer(file?.signalXFilesId, 'original');
              } else {
                this.multipleAttachArray.push(file);
                // this.viewFileFromServer(file?.signalXFilesId,"attach")
              }
            });
          }

          if (this.multipleAttachArray.length > 0) {
            this.viewFileFromServer(
              this.multipleAttachArray[0]?.signalXFilesId,
              'attach',
            );
          }
        },
        (error) => {
          if (error.status !== 403) {
            this._helperService.handleError(error);
          }
        },
      );
  }

  navChange(e) {
    if (e.nextId === 10) {
      this.preparePagination({
        pageSize: this.allLogs['pageSize'],
        currentPage: this.allLogs['currentPage'],
        totalCount: this.allLogs['totalCount'],
      });
    }

    if (e.nextId === 9) {
      this.preparePagination({
        pageSize: this.logs['pageSize'],
        currentPage: this.logs['currentPage'],
        totalCount: this.logs['totalCount'],
      });
    }

    if (e.nextId === 8) {
      this.preparePagination({
        pageSize: this.tasks['pageSize'],
        currentPage: this.tasks['currentPage'],
        totalCount: this.tasks['totalCount'],
      });
    }

    if (e.nextId === 7) {
      this.preparePagination({
        pageSize: this.followupsTransactions['pageSize'],
        currentPage: this.followupsTransactions['currentPage'],
        totalCount: this.followupsTransactions['totalCount'],
      });
    }

    if (e.nextId === 3 || e.nextId === 4) {
      this.preparePagination({
        pageSize: this.Referrals['pageSize'],
        currentPage: this.Referrals['currentPage'],
        totalCount: this.Referrals['totalCount'],
      });
    }
  }

  getDepartment(id) {
    const department = this.listOfDepartment?.filter(
      (item) => item?.id === id,
    )[0];
    return department;
  }

  backButton(transactionId) {
    ////console.log(this.fromDraft);

    this._router.navigate([`/${this.boxType}/read/${transactionId}`], {
      state: { location: `${this.boxType}` },
    });

    // if(this.fromDraft){
    //   this._router.navigate([`/${this.boxType}/read/${transactionId}`],{state: {location: `${this.boxType}`}});
    // }
    // else{
    //   this._router.navigate([`/${this.boxType}/view/${transactionId}`],{state: {location: `${this.boxType}`}});
    // }
  }

  goToLinked(linkedId, transactionId) {
    this._router.navigate([`/${this.boxType}/view/${linkedId}`], {
      state: { location: 'linked', id: transactionId },
    });
    this.active = 1;
  }
  /**
   * Open modal
   * @param content modal content
   */
  openModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  async viewFileFromServer(fileId, type) {
    this._transactionService
      .DownloadFile(fileId)
      .subscribe(async (res: any) => {
        const arr = new Uint8Array(res);
        // final blob
        const blob = new Blob([arr], { type: 'application/pdf' });
        if (type === 'original') {
          try {
            const letter = await this.getBase64(blob);
            this.pdfViewer.annotationSettings.author = `${this._authService.userByIdValue?.fullNameAr}_${this._authService.currentUserValue?.userId}_${this._authService.currentUserValue?.tenantId}`;
            this.pdfViewer.toolbar.showToolbarItem(
              <ToolbarItem[]>environment.searchSyncToolbar,
              false,
            );
            this.pdfViewer.annotationSettings.isLock = true;
            this.pdfViewer.load(`${letter}`, null);
          } catch (error) {
            this._helperService.generateMessage(
              'error',
              'Error on loading File!',
            );
          }
        } else {
          // open it on pdf tron

          try {
            const letter = await this.getBase64(blob);
            this.attachViewer.annotationSettings.author = `${this._authService.userByIdValue?.fullNameAr}_${this._authService.currentUserValue?.userId}_${this._authService.currentUserValue?.tenantId}`;
            this.attachViewer.toolbar.showToolbarItem(
              <ToolbarItem[]>environment.searchSyncToolbar,
              false,
            );
            this.attachViewer.annotationSettings.isLock = true;
            this.attachViewer.load(`${letter}`, null);
          } catch (error) {
            this._helperService.generateMessage(
              'error',
              'Error on loading File!',
            );
          }
        }
      });
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  trackAtt(item) {
    return item.index;
  }

  viewerInstanceTwo;
  viewerInstance;

  async viewAttachFromArray(index) {
    this.viewFileFromServer(
      this.multipleAttachArray[index]['signalXFilesId'],
      'attach',
    );
  }

  currentRef;

  selectRef(ref) {
    this.currentRef = ref;
  }

  openModalg(content: any, notes) {
    this.notes = notes;
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
    this.pdfViewer?.destroy();
    this.attachViewer?.destroy();
  }

  displayPrintDetails = false;
  async print() {
    // this.displayPrintDetails = true;
    // this._loaderService.pageLoaded.next(true);
    const container = document.querySelector('.viewToPrint');
    const pages = container.querySelectorAll('.page');
    const pdf = new jsPDF();

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const canvas = await html2canvas(<HTMLElement>page);
      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth = pdfWidth - 20; // Adjust the width as needed
      const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

      if (i === 2) {
        pdf.addPage();
      }

      if (i === 3 && this.Referrals?.length > 0) {
        pdf.addPage();
      }

      if (i === 4 && this.logs?.length > 0) {
        pdf.addPage();
      }

      if (i === 5 && this.views?.length > 0) {
        pdf.addPage();
      }

      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
    }

    this.displayPrintDetails = false;
    //this._loaderService.pageLoaded.next(false);
    window.open(pdf.output('bloburl'), '_blank');
  }

  // async print (){
  //   await html2canvas(document.querySelector('.viewToPrint')).then(
  //     (canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, 'JPEG', 10, 10,190,0,'someAlias','FAST');
  //     pdf.save('test.PDF')
  //   });
  // }

  newPrint() {
    const page = document.getElementById('page');
    const doc = new jsPDF();
    doc.addFont(
      '../../../../assets/fonts/Amiri-Regular.ttf',
      'Amiri',
      'normal',
    );

    doc.addFont('../../../../assets/fonts/Amiri-Bold.ttf', 'Amiri', 'bold');
    doc.setFont('Amiri');
    doc.setFontSize(10);

    const currentPage = 1;
    combineLatest([
      this._transactionService
        .getAllLogs(this.transactionId, this.allLogsTotalCount, currentPage)
        .pipe(catchError(() => of(null))),
      this._transactionService
        .getTransactionReferrals(
          this.transactionId,
          this.referralsTotalCount,
          currentPage,
        )
        .pipe(catchError(() => of(null))),
    ]).subscribe(async (res) => {
      this.allLogs = res[0];
      this.Referrals = res[1];

      const pdfWidth = doc.internal.pageSize.getWidth() - 20;
      const pdfHeight = doc.internal.pageSize.getHeight() - 20;

      const canvas = await html2canvas(<HTMLElement>page);
      this._loaderService.pageLoaded.next(true);
      const imgData = canvas.toDataURL('image/png');

      const img = new Image();
      img.src = imgData;

      // Wait for the image to load
      await new Promise((resolve) => {
        img.onload = resolve;
      });

      let imgWidth = pdfWidth;
      let imgHeight = (img.naturalHeight * imgWidth) / img.naturalWidth;

      if (imgHeight > pdfHeight) {
        imgHeight = pdfHeight;
        imgWidth = (img.naturalWidth * imgHeight) / img.naturalHeight;
      }

      doc.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

      if (
        this.transaction?.copies?.length > 0 &&
        this.permissionStrings.Internal_ECopies_Record
      ) {
        const seenCopies = [];
        const attachmentCopies = [];

        this.transaction?.copies?.map((copy) => {
          if (copy?.unSeen) {
            seenCopies.push('نعم');
          } else {
            seenCopies.push('لا');
          }

          if (copy?.includeAttatchments) {
            attachmentCopies.push('نعم');
          } else {
            attachmentCopies.push('لا');
          }
        });
        doc
          .setTextColor('#3559a8')
          .text('النسخ العادية', pdfWidth, imgHeight + 20);
        autoTable(doc, {
          styles: {
            halign: 'center',
            font: 'Amiri',
          },
          startY: imgHeight + 30,
          didParseCell: (data) => {
            if (data.column.index === 4 && data.section === 'body') {
              const rowIndex = data.row.index;
              const value = seenCopies[rowIndex];
              data.cell.text = value;
            }

            if (data.column.index === 3 && data.section === 'body') {
              const rowIndex = data.row.index;
              const value = attachmentCopies[rowIndex];
              data.cell.text = value;
            }
          },
          html: '#copies',
          headStyles: {
            fillColor: '#3559a8',
            fontStyle: 'normal',
          },
          bodyStyles: {
            fontStyle: 'bold',
            fontSize: 9,
          },
        });
      }

      if (
        this.transaction?.hiddenCopies?.length > 0 &&
        this.permissionStrings.Transaction_View_BCC_IN_Certificate
      ) {
        const hiddenAttachmentCopies = [];

        this.transaction?.hiddenCopies?.map((hidden) => {
          if (hidden?.includeAttatchments) {
            hiddenAttachmentCopies.push('نعم');
          } else {
            hiddenAttachmentCopies.push('لا');
          }
        });

        doc
          .setTextColor('#3559a8')
          .text(
            'النسخ المخفية',
            pdfWidth,
            ((doc as any).lastAutoTable.finalY || imgHeight) + 20,
          );
        autoTable(doc, {
          styles: {
            halign: 'center',
            font: 'Amiri',
          },
          startY: ((doc as any).lastAutoTable.finalY || imgHeight) + 30,
          html: '#hiddenCopies',
          didParseCell: (data) => {
            if (data.column.index === 3 && data.section === 'body') {
              const rowIndex = data.row.index;
              const value = hiddenAttachmentCopies[rowIndex];
              data.cell.text = value;
            }
          },
          headStyles: {
            fillColor: '#3559a8',
            fontStyle: 'normal',
          },
          bodyStyles: {
            fontStyle: 'bold',
            fontSize: 9,
          },
        });
      }

      if (
        this.transaction?.externalDepartmentCopies?.length > 0 &&
        this.permissionStrings.External_ECopies_Record
      ) {
        doc
          .setTextColor('#3559a8')
          .text(
            'النسخ الخارجية',
            pdfWidth,
            ((doc as any).lastAutoTable.finalY || imgHeight) + 20,
          );
        autoTable(doc, {
          styles: {
            halign: 'center',
            font: 'Amiri',
          },
          startY: ((doc as any).lastAutoTable.finalY || imgHeight) + 30,
          html: '#externalCopies',
          headStyles: {
            fillColor: '#3559a8',
            fontStyle: 'normal',
          },
          bodyStyles: {
            fontStyle: 'bold',
            fontSize: 9,
          },
        });
      }

      if (this.allLogs?.results?.length > 0) {
        doc
          .setTextColor('#3559a8')
          .text(
            'سجل الحركات',
            pdfWidth,
            ((doc as any).lastAutoTable.finalY || imgHeight) + 20,
          );
        autoTable(doc, {
          styles: {
            halign: 'center',
            font: 'Amiri',
          },
          startY: ((doc as any).lastAutoTable.finalY || imgHeight) + 30,
          html: '#all-logs',
          headStyles: {
            fillColor: '#3559a8',
            fontStyle: 'normal',
          },
          bodyStyles: {
            fontStyle: 'bold',
            fontSize: 9,
            cellWidth: 32,
          },
        });

        console.log((doc as any).lastAutoTable);
      }

      if (this.Referrals?.results?.length > 0) {
        doc
          .setTextColor('#3559a8')
          .text(
            'الإحالات',
            pdfWidth,
            ((doc as any).lastAutoTable.finalY || imgHeight) + 20,
          );
        autoTable(doc, {
          styles: {
            halign: 'center',
            font: 'Amiri',
          },
          startY: ((doc as any).lastAutoTable.finalY || imgHeight) + 30,
          html: '#referrals',
          headStyles: {
            fillColor: '#3559a8',
            fontStyle: 'normal',
          },
          bodyStyles: {
            fontStyle: 'bold',
            fontSize: 9,
            cellWidth: 32,
          },
        });
      }

      this.displayPrintDetails = false;
      window.open(doc.output('bloburl'), '_blank');
      combineLatest([
        this._transactionService
          .getAllLogs(this.transactionId, 10, currentPage)
          .pipe(catchError(() => of(null))),
        this._transactionService
          .getTransactionReferrals(this.transactionId, 10, currentPage)
          .pipe(catchError(() => of(null))),
      ]).subscribe((res) => {
        this.allLogs = res[0];
        this.preparePagination({
          pageSize: this.allLogs['pageSize'],
          currentPage: this.allLogs['currentPage'],
          totalCount: this.allLogs['totalCount'],
        });

        this.Referrals = res[1];
        this.preparePagination({
          pageSize: this.Referrals['pageSize'],
          currentPage: this.Referrals['currentPage'],
          totalCount: this.Referrals['totalCount'],
        });
      });
    });
  }

  changePage(pageNumber, type) {
    if (type === 'logs') {
      this._transactionService
        .getTransactionLogs(this.transactionId, this.pageSize, pageNumber)
        .pipe(catchError(() => of(null)))
        .subscribe((res) => {
          this.logs = res;
          this.preparePagination({
            pageSize: this.logs['pageSize'],
            currentPage: this.logs['currentPage'],
            totalCount: this.logs['totalCount'],
          });
        });
    }

    if (type === 'allLogs') {
      this._transactionService
        .getAllLogs(this.transactionId, this.pageSize, pageNumber)
        .pipe(catchError(() => of(null)))
        .subscribe((res) => {
          this.allLogs = res;
          this.preparePagination({
            pageSize: this.allLogs['pageSize'],
            currentPage: this.allLogs['currentPage'],
            totalCount: this.allLogs['totalCount'],
          });
        });
    }

    if (type === 'tasks') {
      this._tasksService
        .getTasksByTransactionId(this.transactionId, this.pageSize, pageNumber)
        .pipe(catchError(() => of(null)))
        .subscribe((res) => {
          this.tasks = res;
          this.preparePagination({
            pageSize: this.tasks['pageSize'],
            currentPage: this.tasks['currentPage'],
            totalCount: this.tasks['totalCount'],
          });
        });
    }

    if (type === 'followUps') {
      this._transactionService
        .getFollowupDetailsTransactions(this.transactionId)
        .pipe(catchError(() => of(null)))
        .subscribe((res) => {
          this.followupsTransactions = res;
          this.preparePagination({
            pageSize: this.followupsTransactions['pageSize'],
            currentPage: this.followupsTransactions['currentPage'],
            totalCount: this.followupsTransactions['totalCount'],
          });
        });
    }

    if (type === 'Referrals') {
      this._transactionService
        .getTransactionReferrals(this.transactionId, this.pageSize, pageNumber)
        .pipe(catchError(() => of(null)))
        .subscribe((res) => {
          this.Referrals = res;
          this.preparePagination({
            pageSize: this.Referrals['pageSize'],
            currentPage: this.Referrals['currentPage'],
            totalCount: this.Referrals['totalCount'],
          });
        });
    }
  }

  preparePagination(pagination) {
    this.collectionSize = pagination['totalCount'];
    this.pageSize = pagination['pageSize'];
    this.currentPage = pagination['currentPage'];
  }
}
