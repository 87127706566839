import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { HelperService } from '../helpers/helper.service';
import { FileUploadService } from '../services/file-upload.service';

@Directive({
  selector: '[appEnhancedUploadFile]',
})
export class EnhancedUploadFileDirective {
  isHovered = false;
  constructor(
    private sanitizer: DomSanitizer,
    private _toastrService: HelperService,
    private fileService: FileUploadService,
  ) {}

  @Input() Minlimit = 1;
  @Input() Maxlimit = 7;

  @HostListener('change', ['$event'])
  onDrop($event: any) {
    $event.preventDefault();
    $event.stopPropagation();

    if ($event?.target?.files.length > 0) {
      this.uploadFile($event);
    }
  }

  @Output() fileUploaded = new EventEmitter<any>();
  @Output() fileSrcUrl = new EventEmitter<any>();
  @Output() fileNameEv = new EventEmitter<any>();

  @Input() imageUploaded = false;
  @Input() currentGroup!: FormGroup;
  @Input() type!: string;
  @Input() imageUrl!: string;
  @Input() fileType!: string[];
  @Input() fileSize = 13;
  @Input() multiFile = false;
  @Input() label = 'file-upload';
  @Input() formControlLabel = 'file';
  @Input() clearName = false;

  fileName!: string;
  fileSrc!: any;

  filesArray: any = [];

  ngOnInit() {
    this.currentGroup?.controls[this.formControlLabel]?.clearValidators();
    this.currentGroup?.controls[
      this.formControlLabel
    ]?.updateValueAndValidity();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['clearName']?.currentValue) {
      this.fileName = '';
    }
  }
  isHover(isHover: any) {
    this.isHovered = isHover;
  }

  /*
    event is equal to $event.target.files
  */

  uploadFile($event: any) {
    let ValidType;
    const event: any = $event.target.files;
    if (this.fileType) {
      ValidType = this.checkFileListType(Array.from(event));
    } else {
      ValidType = true;
    }

    const validSize = this.checkFileListSize(Array.from(event));

    if (!validSize) {
      $event.target.value = '';
      this._toastrService.showApiError({
        message_AR: `حجم الملف يتعدي ${this.fileSize} ميجا`,
        message_EN: '',
      });
    }
    if (!ValidType) {
      ////console.log('Dsd');
      $event.target.value = '';
      this._toastrService.showApiError({
        message_AR: 'صيغة الملف غير مدعومه!',
        message_EN: '',
      });
    }

    if (event && validSize && ValidType) {
      if (this.multiFile) {
        if (Array.from(event).length >= this.Minlimit) {
          if (Array.from(event).length <= this.Maxlimit) {
            this.MultiFileUpload(event);
          } else {
            this._toastrService.showApiError({
              message_AR: `!عدد الملفات اقل من المسموح ${this.Maxlimit} ملف`,
              message_EN: '',
            });
          }
        } else {
          this._toastrService.showApiError({
            message_AR: `! عدد الملفات اقل من المسموح ${this.Minlimit} ملف`,
            message_EN: '',
          });
        }
      } else {
        this.singleFileUpload(event);
      }
    }
  }

  singleFileUpload(event: any) {
    // ////console.log('hello');
    // ////console.log('hello');
    // let ValidType;
    // if (this.fileType) {
    //   ValidType = event
    //     .item(0)
    //     ?.type.includes(`${this.fileType[0] ? this.fileType[0] : 'image'}`);
    // } else {
    //   ValidType = true;
    // }
    // // @ts-ignore
    // const validSize = this.getFileSize(event.item(0)?.size) <= this.fileSize;

    // if (!validSize) {
    //   this._toastrService.error(
    //     `File Size Exceed ${this.fileSize} MB`,
    //     'Error'
    //   );
    // }
    // if (!ValidType) {
    //   this._toastrService.error('File type not supported', 'Error');
    // }

    // if (validSize && ValidType) {
    // display file
    this.previewFile(event);
    // display file

    const formData = new FormData();

    formData.append('File', event.item(0));

    this.fileService
      .uploadListFiles(formData)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.fileUploaded.emit(res['result']);
      });

    this.fileNameEv.emit(event.item(0).name);
    this.currentGroup?.controls[this.formControlLabel]?.clearValidators();
    this.currentGroup?.controls[
      this.formControlLabel
    ]?.updateValueAndValidity();
    this.fileName = event.item(0).name;
    // }
  }

  MultiFileUpload(event: any) {
    Array.from(event).map((file) => {
      if (this.checkFileListSize([file]) && this.checkFileListType([file])) {
        this.filesArray.push(file);
      }
    });
    // this.filesArray = Array.from(event);

    if (!this.checkFileListType(this.filesArray)) {
      this._toastrService.showApiError({
        message_AR: 'File type not supported!',
        message_EN: 'File type not supported',
      });
    }
    if (!this.checkFileListSize(this.filesArray)) {
      this._toastrService.showApiError({
        message_AR: `File Size Exceed ${this.fileSize} MB`,
        message_EN: `File Size Exceed ${this.fileSize} MB`,
      });
    }

    if (
      this.checkFileListType(this.filesArray) &&
      this.checkFileListSize(this.filesArray)
    ) {
      // //////////console.log("valid files")
      // display file
      this.previewFile(event);
      // display files

      const formData = new FormData();

      this.filesArray.map((file: any) => {
        formData.append('File', file);
      });

      this.fileService
        .uploadListFiles(formData)
        .pipe(take(1))
        .subscribe((res: any) => {
          this.fileUploaded.emit(res['result']);
        });

      // this.fileUploaded.emit(this.filesArray);
      this.fileNameEv.emit(this.filesArray[0].name);
      this.currentGroup?.controls[this.formControlLabel]?.clearValidators();
      this.currentGroup?.controls[
        this.formControlLabel
      ]?.updateValueAndValidity();
      // this.fileName = event.item(0).name;
    }
  }

  singleFilePreview(event: any) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const file = reader.result;
        // for svg image proccessed as unsafe;
        this.fileSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`${file}`);
        this.fileSrcUrl.emit(this.fileSrc);
      },
      false,
    );

    if (event.item(0)) {
      reader.readAsDataURL(event.item(0));
    }
  }

  fileListUrl = [];

  MultiFilePreview(event: any) {
    for (let i = 0; i < event.length; i++) {
      const reader = new FileReader();
      reader.readAsDataURL(event[i]);
      reader.addEventListener(
        'load',
        (event: any) => {
          const file = event?.target?.result;
          // for svg image proccessed as unsafe;

          // @ts-ignore
          this.fileListUrl.push(
            // @ts-ignore
            this.sanitizer.bypassSecurityTrustResourceUrl(`${file}`),
          );
        },
        false,
      );
    }
  }

  // checkFileListType(fileList: any) {
  //   // @ts-ignore
  //   const validType = fileList.every((file) =>
  //     file?.type.includes(`${this.fileType ? this.fileType : 'image'}`)
  //   );
  //   // fileList.every(file => //////////console.log(file?.type));
  //   // //////////console.log(validType)
  //   return validType;
  // }

  checkFileListType(fileList: any) {
    //@ts-ignore
    // const validType = fileList.every((file) => {
    //   return this.fileType.some((item) => {
    //     return file?.type.includes(item);
    //   });
    // });
    // return validType;
    // we write accept value
    return true;
  }

  checkFileListSize(fileList: any) {
    const validSize = fileList.every(
      // @ts-ignore
      (file) => this.getFileSize(file?.size) <= this.fileSize,
    );
    return validSize;
  }

  setFileName(name: any) {
    this.fileName = name;
  }

  getFileSize(size: any) {
    let fileSize;
    if (size) {
      fileSize = parseFloat((size / (1024 * 1024)).toFixed(2));
      return fileSize;
    }
    return fileSize;
  }

  previewFile(event: any) {
    if (this.multiFile) {
      this.MultiFilePreview(event);
    } else {
      this.singleFilePreview(event);
    }
  }

  trackFn(index: any) {
    return index;
  }

  removeFile(index: any) {
    // remove item form formArray
    this.filesArray.splice(index, 1);
    // emmit new value to parent component
    this.fileUploaded.emit(this.filesArray);
    // preview files
    this.fileListUrl.splice(index, 1);
  }

  setFileListUrl($event: any) {
    this.fileListUrl = $event;
  }

  setFileArray($event: any) {
    this.fileUploaded.emit($event);
    this.filesArray = $event;
  }

  dropped($event: any) {
    this.fileUploaded.emit($event);
  }
}
