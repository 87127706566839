import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EMPTY, fromEvent, pipe, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { HelperService } from '../../helpers/helper.service';
import { TransactionService } from '../../services/transaction.service';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search-transaction',
  templateUrl: './search-transaction.component.html',
  styleUrls: ['./search-transaction.component.scss'],
})
export class SearchTransactionComponent implements OnDestroy, AfterViewInit {
  constructor(
    private transactionService: TransactionService,
    private _helperService: HelperService,
    private searchService: SearchService,
  ) {}

  arabicNumbers = /[\u0660-\u0669]/;

  @Input() searchTerm;
  @Input() typeTransaction;
  @Input() state;
  firstSearch = true;
  @Output() transactionInbox: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Input() reserved = false;
  @Input() placeholder = 'بحث';

  @Input() transactionTypeId = 1;

  @ViewChild('searchInput') searchInput: ElementRef;
  _unsubscribeAll = new Subject();

  search() {
    if (this.searchTerm === '') {
      this.transactionService
        .filterTransactionOpt({
          box: this.typeTransaction,
          state: this.state ? this.state : null,
        })
        .subscribe((res: any) => {
          this.transactionInbox.emit(res.results);
        });
    }
    if (this.searchTerm) {
      if (this.searchTerm.match(/^\d+$/)) {
        this.transactionService
          .filterTransactionOpt({
            transactionNumber: +this.searchTerm,
            box: this.typeTransaction,
            state: this.state ? this.state : null,
          })
          .subscribe((res: any) => {
            this.transactionInbox.emit(res.results);
          });
      } else if (this.searchTerm.match(this.arabicNumbers)) {
        const EnglishString = this.searchTerm.replace(/[\u0660-\u0669]/g, (d) =>
          '٠١٢٣٤٥٦٧٨٩'.indexOf(d),
        );
        this.transactionService
          .filterTransactionOpt({
            transactionNumber: +EnglishString,
            box: this.typeTransaction,
            state: this.state ? this.state : null,
          })
          .subscribe((res: any) => {
            this.transactionInbox.emit(res.results);
          });
      } else {
        this.transactionService
          .filterTransactionOpt({
            subject: this.searchTerm,
            box: this.typeTransaction,
            state: this.state ? this.state : null,
          })
          .subscribe((res: any) => {
            this.transactionInbox.emit(res.results);
          });
      }
    }
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(debounceTime(500), takeUntil(this._unsubscribeAll))
      .subscribe(
        (searchResult: any) => {
          this.getSearchResult(searchResult);
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );

    fromEvent(this.searchInput.nativeElement, 'blur')
      .pipe(debounceTime(500), takeUntil(this._unsubscribeAll))
      .subscribe(
        (searchResult: any) => {
          this.getSearchResult(searchResult);
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );

    fromEvent(this.searchInput.nativeElement, 'focus')
      .pipe(debounceTime(500), takeUntil(this._unsubscribeAll))
      .subscribe(
        (searchResult: any) => {
          this.getSearchResult(searchResult);
        },
        (error) => {
          this._helperService.handleError(error);
        },
      );
  }

  getSearchResult(event) {
    const term = event?.target?.value.toLocaleLowerCase();
    if (term?.trim() === '') {
      if (!this.firstSearch) {
        this.transactionService
          .filterTransactionOpt({
            box: this.typeTransaction,
            state: this.state ? this.state : null,
          })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            this.transactionInbox.emit(res.results);
            this.firstSearch = false;
          });
      }
    } else {
      if (term.match(/^\d+$/)) {
        this.transactionService
          .filterTransactionOpt({
            transactionNumber: +term,
            box: this.typeTransaction,
            state: this.state ? this.state : null,
          })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            this.transactionInbox.emit(res.results);
          });
      } else if (term.match(this.arabicNumbers)) {
        const EnglishString = term.replace(/[\u0660-\u0669]/g, (d) =>
          '٠١٢٣٤٥٦٧٨٩'.indexOf(d),
        );
        this.transactionService
          .filterTransactionOpt({
            transactionNumber: +EnglishString,
            box: this.typeTransaction,
            state: this.state ? this.state : null,
          })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            this.transactionInbox.emit(res.results);
          });
      } else {
        this.transactionService
          .filterTransactionOpt({
            subject: term,
            box: this.typeTransaction,
            state: this.state ? this.state : null,
          })
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res: any) => {
            this.transactionInbox.emit(res.results);
          });
      }

      this.firstSearch = false;
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next('');
    this._unsubscribeAll.complete();
  }
}
