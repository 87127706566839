 export const  TransactionType = [
    {
            id:1,
            nameAr : "وارد",
            nameEn : "Inbound"
    },
    {
        id:2,
        nameAr : "خارجي",
        nameEn : "Outbound"
    },
    {
        id:3,
        nameAr : "داخلي",
        nameEn : "Internal"
    }
]


export const  TransactionType2 = [
    {
            id:1,
            descriptionAr : "وارد",
            descriptionEn : "Inbound"
    },
    {
        id:2,
        descriptionAr : "خارجي",
        descriptionEn : "Outbound"
    },
    {
        id:3,
        descriptionAr : "داخلي",
        descriptionEn : "Internal"
    }
]