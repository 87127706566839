<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!-- <div     class="dropdown mt-4 mt-sm-0" ngbDropdown>
    <a class="btn btn-secondary dropdown-toggle" ngbDropdownToggle>
      <i class="add fa-light fa-circle-plus"></i><span>إنشاء جديد</span><i class="arrow fa-solid fa-chevron-down"></i>
    </a>
    <div     class="dropdown-menu" ngbDropdownMenu>
      <a class="dropdown-item" href="javascript: void(0);">Action</a>
      <a class="dropdown-item" href="javascript: void(0);">Another action</a>
      <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
    </div>
  </div> -->
  <!--- Sidemenu -->

  <div id="sidebar-menu" class="sm-scroll">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- WAS LOGO -->
      <div class="d-flex justify-content-between sidebar-btn-area">
        <div>
          <a routerLink="/" class="logo logo-dark">
            <span class="logo-sm">
              <img
                appLoaderImage
                [loadedImage]="clientBrandImageSm"
                [defaultImage]="'/assets/images/signalx-sm.png'"
                class="client-logo"
                alt=""
              />
            </span>
            <span class="logo-lg">
              <img
                appLoaderImage
                [loadedImage]="clientBrandImage"
                class="client-logo"
                alt=""
              />
            </span>
          </a>

          <a routerLink="/" class="logo logo-light">
            <span class="logo-sm">
              <img
                appLoaderImage
                [loadedImage]="clientBrandImageSm"
                [defaultImage]="'/assets/signalx-sm.png'"
                class="client-logo"
                alt=""
              />
            </span>
            <span class="logo-lg">
              <img
                appLoaderImage
                [loadedImage]="clientBrandImage"
                class="client-logo"
                alt=""
              />
            </span>

            <!-- <span class="logo-sm">
              <img [src]="clientBrandImageSm" class="client-logo" alt="" />
            </span>
            <span class="logo-lg">
              <img [src]="clientBrandImage" class="client-logo" alt="" />
            </span> -->
          </a>
        </div>

        <!-- <img [src]="clientBrandImage" class="client-logo" alt="client_Logo"> -->
        <button
          type="button"
          class="btn btn-sm px-3 font-size-16"
          id="vertical-menu-btn"
          (click)="toggleMobileMenu($event)"
        >
          <i class="fa-light fa-arrow-left-from-line"></i>
        </button>
      </div>

      <!--
      <div     class="d-flex justify-content-between sidebar-btn-area">
        <img [src]="clientBrandImage" class="client-logo" alt="client_Logo">
         <button type="button" class="btn btn-sm px-3 font-size-16" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
          <i class="fa-light fa-arrow-left-from-line"></i>
        </button>
      </div> -->

      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems; let i = index">
        <li
          class="menu-title"
          *ngIf="
            item?.isTitle &&
            item.role.indexOf(userRole) > -1 &&
            (item?.permissions
              | isAllowedToShowPipe: item?.contentType : userRole)
          "
        >
          {{ item.label | translate }}
        </li>
        <li
          *ngIf="
            !item?.isTitle &&
            !item.isLayout &&
            item.role.indexOf(userRole) > -1 &&
            (item?.permissions
              | isAllowedToShowPipe: item?.contentType : userRole)
          "
        >
          <!-- [ngbTooltip]="isCondensed ? item.label : '' " placement="left" -->
          <a
            *ngIf="hasItems(item) && item.role.indexOf(userRole) > -1"
            href="javascript:void(0);"
            class="is-parent"
            [ngClass]="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
            routerLinkActive="active"
          >
            <i
              [ngClass]="{ bx: !item?.awesomeIcon, fa: item?.awesomeIcon }"
              class="{{ item.icon }}"
              *ngIf="item.icon"
            ></i>

            <span> {{ item.label | translate }}</span>
            <span
              class="badge rounded-pill float-end bg-{{ item.badge.variant }}"
              *ngIf="item.badge"
              >{{ item.badge.text | translate }}</span
            >
          </a>

          <a
            routerLinkActive="active"
            [routerLink]="item.link"
            *ngIf="!hasItems(item) && item.role.indexOf(userRole) > -1"
            class="side-nav-link-ref"
            routerLinkActive="active"
          >
            <i
              [ngClass]="{ bx: !item?.awesomeIcon, fa: item?.awesomeIcon }"
              class="{{ item.icon }}"
              *ngIf="item.icon"
            ></i>
            <span> {{ item.label | translate }}</span>
            <span
              class="badge rounded-pill bg-{{ item.badge.variant }} float-end"
              *ngIf="item.badge && boxesCounter[item.badge.index] > 0"
              >{{ boxesCounter[item.badge.index] }}</span
            >
          </a>

          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems">
              <ng-container
                *ngIf="
                  subitem?.permissions
                    | isAllowedToShowPipe: subitem?.contentType : userRole
                "
              >
                <a
                  routerLinkActive="active"
                  [routerLink]="subitem.link"
                  *ngIf="!hasItems(subitem)"
                  class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId"
                  routerLinkActive="active"
                >
                  <i
                    [ngClass]="{
                      bx: !subitem?.awesomeIcon,
                      fa: subitem?.awesomeIcon
                    }"
                    class="{{ subitem.icon }}"
                    *ngIf="subitem.icon"
                  ></i>
                  {{ subitem.label | translate }}
                </a>
                <a
                  routerLinkActive="active"
                  *ngIf="hasItems(subitem)"
                  class="side-nav-link-a-ref has-arrow"
                  href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId"
                >
                  {{ subitem.label | translate }}
                </a>
              </ng-container>

              <ul
                *ngIf="hasItems(subitem)"
                class="sub-menu mm-collapse"
                aria-expanded="false"
              >
                <li *ngFor="let subSubitem of subitem.subItems">
                  <ng-container
                    *ngIf="
                      subSubitem?.permissions
                        | isAllowedToShowPipe
                          : subSubitem?.contentType
                          : userRole
                    "
                  >
                    <a
                      routerLinkActive="active"
                      [attr.data-parent]="subSubitem.parentId"
                      [routerLink]="subSubitem.link"
                      routerLinkActive="active"
                      class="side-nav-link-ref"
                    >
                      <i
                        [ngClass]="{
                          bx: !subSubitem?.awesomeIcon,
                          fa: subSubitem?.awesomeIcon
                        }"
                        class="{{ subSubitem.icon }}"
                        *ngIf="subSubitem.icon"
                      ></i>
                      {{ subSubitem.label | translate }}
                    </a>
                  </ng-container>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
