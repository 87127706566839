import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signal-logo',
  templateUrl: './signal-logo.component.html',
  styleUrls: ['./signal-logo.component.scss'],
})
export class SignalLogoComponent {
  constructor() {}
}
