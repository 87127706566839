import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { isAllowedToShowPipe } from './premission.pipe';
import { ShowElementPipe } from './show-element.pipe';
import { GetbarcodeobjectPipe } from './getbarcodeobject.pipe';
import { HijirydatePipe } from './hijirydate.pipe';
import { HijrydatetimePipe } from './hijrydatetime.pipe';
import { AuthproviderPipe } from './authprovider.pipe';

@NgModule({
  declarations: [
    isAllowedToShowPipe,
    ShowElementPipe,
    GetbarcodeobjectPipe,
    HijirydatePipe,
    HijrydatetimePipe,
    AuthproviderPipe,
  ],
  imports: [CommonModule],
  exports: [
    isAllowedToShowPipe,
    ShowElementPipe,
    GetbarcodeobjectPipe,
    HijirydatePipe,
    HijrydatetimePipe,
    AuthproviderPipe,
  ],
})
export class PipesModule {}
