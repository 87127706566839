import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropZoneDirective } from './directives/appdropzone.directive';
import { ToastrModule } from 'ngx-toastr';
import { ToggleClassDirective } from './directives/toggle-class.directive';
import { NoDataComponent } from './components/no-data/no-data.component';
import { SignalLogoComponent } from './components/signal-logo/signal-logo.component';
import { isAllowedToShowPipe } from './pipes/premission.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { EnhancedUploadFileDirective } from './directives/enhanced-upload-file.directive';
import { DetailsComponentsComponent } from './components/details-components/details-components.component';
import {
  NgbDropdownModule,
  NgbNavModule,
  NgbTooltipModule,
  NgbAccordionModule,
  NgbDatepickerModule,
  NgbPaginationModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AnnotationService,
  BookmarkViewService,
  LinkAnnotationService,
  MagnificationService,
  NavigationService,
  PdfViewerModule,
  PrintService,
  TextSearchService,
  TextSelectionService,
  ThumbnailViewService,
  ToolbarService,
} from '@syncfusion/ej2-angular-pdfviewer';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { SearchTransactionModule } from './components/search-transaction/search-transaction.module';
import { PipesModule } from './pipes/pipes.module';
import { HijirydatePipe } from './pipes/hijirydate.pipe';
import { UserNotificationsSettingsComponent } from './components/user-notifications-settings/user-notifications-settings.component';
import { BrandViewComponent } from './components/brand-view/brand-view.component';

@NgModule({
  declarations: [
    UploadFileComponent,
    DropZoneDirective,
    ToggleClassDirective,
    NoDataComponent,
    SignalLogoComponent,
    LoaderComponent,
    EnhancedUploadFileDirective,
    DetailsComponentsComponent,
    UserNotificationsSettingsComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    NgxQRCodeModule,
    NgbAccordionModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    PipesModule,
    SearchTransactionModule,
    PdfViewerModule,
  ],
  exports: [
    UploadFileComponent,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    DropZoneDirective,
    ToastrModule,
    ToggleClassDirective,
    NoDataComponent,
    LoaderComponent,
    DetailsComponentsComponent,
    PipesModule,
    UserNotificationsSettingsComponent,
  ],

  providers: [
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService,
    NavigationService,
    AnnotationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
  ],
})
export class CoreModule {}
