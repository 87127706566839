export const ReceiveMethod = [
    {
        id:1,
        nameAr : "إلكتروني",
        nameEn : "Electronic"
    },
    {
        id:2,
        nameAr : "ورقي",
        nameEn : "Paper"
    },
    {
        id:3,
        nameAr : "ورقي إلكتروني",
        nameEn : "Paper Electronic"
    }
]