<div class="row">
  <div class="col-xl-12">
    <div class="card overflow-hidden">
      <div class="bg-soft bg-baseColor pt-5">
        <div class="p-3">
          <div class="pt-4">
            <h5 class="font-size-15 text-truncate text-white">
              {{ userName }}
            </h5>
            <p class="mb-0 text-truncate text-white">
              {{ userRole }}
            </p>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="row justify-content-between mt-2">
          <div class="col-sm-4">
            <div class="mt-2">
              <h5 class="mb-0 text-truncate">إعدادات التنبيهات</h5>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="d-flex justify-content-end">
              <button
                class="btn bg-baseColor text-white"
                (click)="saveConfig()"
              >
                حفظ
              </button>
              <button
                class="btn btn-white text-baseColor bg-light ms-2"
                routerLink="/dashboard"
              >
                إلغاء
              </button>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <form [formGroup]="notificationsForm" (ngSubmit)="saveConfig()">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2 fw-bold text-baseColor"
                    >الرسائل النصية</label
                  >
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="smsSelect"
                      [checked]="isChecked('sms')"
                      (change)="changeCheckALl('sms', $event)"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة نصية قصيرة للمعاملات الجديدة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      formControlName="smsNewTransactions"
                      name="smsSelect"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة نصية قصيرة للمهام الجديدة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      formControlName="smsNewTasks"
                      name="smsSelect"
                      value="2"
                      id=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة نصية قصيرة لطلب المتابعة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      formControlName="smsFollowUp"
                      name="smsSelect"
                      value="3"
                      id=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة نصية قصيرة للمعاملات المشاركة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      formControlName="smsSharedTransactions"
                      name="smsSelect"
                      value="4"
                      id=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة نصية قصيرة للمعاملات العامة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      formControlName="smsTransactionGeneral"
                      name="smsSelect"
                      value="4"
                      id=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة نصية قصيرة لنسخ المعاملات
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      formControlName="smsTransactionCopy"
                      name="smsSelect"
                      value="4"
                      id=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة نصية قصيرة للمهام المغلقة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      formControlName="smsTaskClosed"
                      name="smsSelect"
                      value="4"
                      id=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة نصية قصيرة لطلب التحذير
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      formControlName="smsAlarmRequest"
                      name="smsSelect"
                      value="4"
                      id=""
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة نصية قصيرة لطلب التمديد
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      formControlName="smsExtendRequest"
                      name="smsSelect"
                      value="4"
                      id=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2 fw-bold text-baseColor"
                    >رسائل البريد الالكتروني</label
                  >
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      [checked]="isChecked('email')"
                      (change)="changeCheckALl('email', $event)"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي بريد الكتروني للمعاملات الجديدة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      id=""
                      formControlName="emailNewTransactions"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي رسالة بريد الكتروني للمهام الجديدة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      formControlName="emailNewTasks"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي بريد الكتروني لطلب المتابعة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      formControlName="emailFollowUp"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي بريد الكتروني للمعاملات المشاركة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      formControlName="emailSharedTransactions"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي بريد الكتروني للمعاملات العامة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      formControlName="emailTransactionGeneral"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي بريد الكتروني لنسخ المعاملات
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      formControlName="emailTransactionCopy"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي بريد الكتروني غلق المهام
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      formControlName="emailTaskClosed"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي بريد الكتروني طلب الانذار
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      formControlName="emailAlarmRequest"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي بريد الكتروني طلب التمديد
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="selectedMail"
                      formControlName="emailExtendRequest"
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2 fw-bold text-baseColor"
                    >إرسال تنبيهات للنظام</label
                  >
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      [checked]="isChecked('push')"
                      (change)="changeCheckALl('push', $event)"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي تنبيه للمعاملات الجديدة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      id=""
                      formControlName="pushNotificationNewTransactions"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي تنبيه للمهام الجديدة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      formControlName="pushNotificationNewTasks"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي تنبيه لطلب المتابعة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      formControlName="pushNotificationFollowUp"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي تنبيه للمعاملات المشاركة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      formControlName="pushNotificationSharedTransactions"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي تنبيه للمعاملات العامة
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      formControlName="pushNotificationTransactionGeneral"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي تنبيه لنسخ المعاملات
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      formControlName="pushNotificationTransactionCopy"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي تنبيه غلق المهام
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      formControlName="pushNotificationTaskClosed"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي تنبيه طلب الانذار
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      formControlName="pushNotificationAlarmRequest"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6"></div>

              <div class="col-md-6">
                <div class="mb-1 parallel d-flex align-items-center">
                  <label class="form-label mx-2"
                    >أرسل لي تنبيه طلب التمديد
                  </label>
                  <div class="flex-grow d-flex justify-content-end">
                    <input
                      type="checkbox"
                      name="pushNotifications"
                      formControlName="pushNotificationExtendRequest"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- end card -->
  </div>
</div>
