import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { HijiryDateService } from '../services/hijiry-date.service';

@Pipe({
  name: 'hijrydatetime',
})
export class HijrydatetimePipe implements PipeTransform {
  constructor(private hijriService: HijiryDateService) {}
  transform(value: string, ...args: unknown[]): unknown {
    const allowHijri = JSON.parse(localStorage.getItem('allowHijri'));
    if (value) {
      if (allowHijri) {
        const date = value
          .split('T')[0]
          .split('-')
          .map((item) => +item);
        const time = value.split('T')[1];
        return this.hijriService.writeIslamicDateReady(date, -1, time);
      } else {
        return formatDate(value, 'MMM d, y, h:mm:ss a', 'en-US');
      }
    }
  }
}
